import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { ForgetMeContext } from './context'

import ModalForgetMe from './index.presentational'

import { ContainerProps } from './types'

const ModalForgetMeContainer: React.FC<ContainerProps> = ({
  history,
  onCancel,
  onConfirm,
}): React.ReactElement => {
  const {
    isOpen: isForgetMeOpen,
    setIsOpen: setIsForgetMeOpen,
    isSuccessOpen: isForgetMeSuccessOpen,
    setIsSuccessOpen: setIsForgetMeSuccessOpen,
    resetSession,
  } = useContext(ForgetMeContext)

  const cancel = (): void => {
    onCancel && onCancel()

    setIsForgetMeOpen(false)
  }

  const confirm = (): void => {
    setIsForgetMeOpen(false)

    resetSession()

    history.push('/quote/new')

    onConfirm && onConfirm()
  }

  return React.createElement(ModalForgetMe, {
    modalForgetMe: {
      isOpen: isForgetMeOpen,
      onRequestClose: (): void => cancel(),
    },
    modalForgetMeSuccess: {
      isOpen: isForgetMeSuccessOpen,
      onRequestClose: (): void => setIsForgetMeSuccessOpen(false),
    },
    onCancel: cancel,
    onConfirm: confirm,
  })
}

export default withRouter(ModalForgetMeContainer)
