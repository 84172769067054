import { useCallback } from 'react'

import * as config from 'config'

import type { PushDataLayerEventProps } from './types'

export const useGoogleTagManager = () => {
  const pushDataLayerEvent = useCallback((props: PushDataLayerEventProps) => {
    if (!window?.dataLayer?.push) {
      return
    }

    const dataLayer = {
      event_trigger: 'GAevent',
      page_url: window?.location?.href,
      ...props,
    }

    window.dataLayer.push(function(this: { reset: () => void }) {
      this.reset()
    })

    window.dataLayer.push(dataLayer)
  }, [])

  return { pushDataLayerEvent }
}

const mapEventsToEventIds = {
  cta_get_quote: '01',
  quote_form_started: '02',
  zip_submitted: '03',
  select_item: '04',
  email_submitted: '05',
  extra_info_added1: '06',
  vehicle_confirmed: '07',
  extra_info_added2: '08',
  driver_confirmed: '09',
  incidents_confirmed: '10',
  policy_info_added: '11',
  select_coverage_level: '12',
  quotes_listed: '13',
  select_quote: '14',
  quote_requested: '15',
  cta_ask_question: '16',
  cta_view_location: '17',
}

export const events = Object.entries(mapEventsToEventIds).reduce((acc, [
  event,
  event_id,
]) => {
  acc[event as keyof typeof mapEventsToEventIds] = {
    event,
    event_id,
  }

  return acc
}, {} as Record<keyof typeof mapEventsToEventIds, {
  event: string,
  event_id: string,
}>)

export const googleTagManagerHtml = {
  head: (): string => {
    if (!canInit()) {
      return ''
    }

    return `<script>
window.dataLayer = window.dataLayer || [];
</script>
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${config.googleTagManager.containerId}');</script>
<!-- End Google Tag Manager -->`
  },

  body: (): string => {
    if (!canInit()) {
      return ''
    }

    return `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${config.googleTagManager.containerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`
  },
}

const canInit = (): boolean => {
  if (!config.googleTagManager.isEnabled) {
    return false
  }

  if (!config.googleTagManager.containerId) {
    console.error('🐞 Google Tag Manager container ID is required.') // eslint-disable-line no-console

    return false
  }

  return true
}

export * from './types'
